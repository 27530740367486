import { Slot } from '@radix-ui/react-slot'
import { type HTMLAttributes } from 'react'
import { cn } from '#app/utils/misc.js'

type CommonProps = {
	asChild?: boolean
}

type H1Props = HTMLAttributes<HTMLHeadingElement> & CommonProps

function H1({ className, asChild, ...props }: H1Props) {
	const Comp = asChild ? Slot : 'h1'

	return (
		<Comp
			className={cn(
				'text-foreground scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl',
				className,
			)}
			{...props}
		/>
	)
}
type H2Props = HTMLAttributes<HTMLHeadingElement> & CommonProps

function H2({ className, asChild, ...props }: H2Props) {
	const Comp = asChild ? Slot : 'h2'

	return (
		<Comp
			className={cn(
				'text-foreground scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0',
				className,
			)}
			{...props}
		/>
	)
}

type H3Props = HTMLAttributes<HTMLHeadingElement> & CommonProps

function H3({ className, asChild, ...props }: H3Props) {
	const Comp = asChild ? Slot : 'h3'

	return (
		<Comp
			className={cn(
				'text-foreground scroll-m-20 text-2xl font-semibold tracking-tight',
				className,
			)}
			{...props}
		/>
	)
}

type H4Props = HTMLAttributes<HTMLHeadingElement> & CommonProps

function H4({ className, asChild, ...props }: H4Props) {
	const Comp = asChild ? Slot : 'h4'

	return (
		<Comp
			className={cn(
				'text-foreground scroll-m-20 text-xl font-semibold tracking-tight',
				className,
			)}
			{...props}
		/>
	)
}

type ParagraphProps = HTMLAttributes<HTMLParagraphElement> & CommonProps

function Paragraph({ className, asChild, ...props }: ParagraphProps) {
	const Comp = asChild ? Slot : 'p'

	return (
		<Comp
			className={cn(
				'text-foreground leading-7 [&:not(:first-child)]:mt-6',
				className,
			)}
			{...props}
		/>
	)
}

type LeadProps = HTMLAttributes<HTMLParagraphElement> & CommonProps

function Lead({ className, asChild, ...props }: LeadProps) {
	const Comp = asChild ? Slot : 'p'

	return (
		<Comp
			className={cn('text-muted-foreground text-xl', className)}
			{...props}
		/>
	)
}

type LargeProps = HTMLAttributes<HTMLParagraphElement> & CommonProps

function Large({ className, asChild, ...props }: LargeProps) {
	const Comp = asChild ? Slot : 'p'

	return (
		<Comp
			className={cn('text-foreground text-lg font-semibold', className)}
			{...props}
		/>
	)
}

type SmallProps = HTMLAttributes<HTMLParagraphElement> & CommonProps

function Small({ className, asChild, ...props }: SmallProps) {
	const Comp = asChild ? Slot : 'small'

	return (
		<Comp
			className={cn(
				'text-foreground text-sm font-medium leading-none',
				className,
			)}
			{...props}
		/>
	)
}

type MutedProps = HTMLAttributes<HTMLParagraphElement> & CommonProps

function Muted({ className, asChild, ...props }: MutedProps) {
	const Comp = asChild ? Slot : 'p'

	return (
		<Comp
			className={cn('text-muted-foreground text-sm', className)}
			{...props}
		/>
	)
}

type StrongProps = HTMLAttributes<HTMLParagraphElement> & CommonProps

function Strong({ className, asChild, ...props }: StrongProps) {
	const Comp = asChild ? Slot : 'strong'

	return (
		<Comp
			className={cn('text-foreground font-semibold', className)}
			{...props}
		/>
	)
}

export const Typography = {
	H1,
	H2,
	H3,
	H4,
	Paragraph,
	Lead,
	Large,
	Small,
	Muted,
	Strong,
}
